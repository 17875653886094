import React from 'react';
import Helmet from 'react-helmet';

function PageRedirect({ pageContext }) {
  return <Helmet>
    <meta
      httpEquiv="refresh"
      content={`0;url=${pageContext.redirect}`}
    />
  </Helmet>
}

export default PageRedirect