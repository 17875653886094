import React from 'react'
import PageRedirect from '../templates/page-redirect'
import { PORTAL_URL } from '../config'
import { isBrowser } from '../services/auth'

function TermsConditions() {
  if (!isBrowser()) {
    return <div />
  }

  let id = new URLSearchParams(window.location.search).get('id')
  
  if (!id) {
    id = window.location.pathname.replace('tc/','')
  }

  return <PageRedirect pageContext={{ redirect: `${PORTAL_URL}/tc/${id}` }} />
}

export default TermsConditions
